var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Nouvelle Certificat"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées de Certificat ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Label","label-for":"Label"}},[_c('validation-provider',{attrs:{"name":"Label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Label"},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Code","label-for":"Code"}},[_c('validation-provider',{attrs:{"name":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Code"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Frais d'inscription","label-for":"Frais d'inscription"}},[_c('validation-provider',{attrs:{"name":"Frais d'inscription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Frais d'inscription"},model:{value:(_vm.registration_fees),callback:function ($$v) {_vm.registration_fees=$$v},expression:"registration_fees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Frais d'examen","label-for":"Frais d'examen"}},[_c('validation-provider',{attrs:{"name":"Frais d'examen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Frais d'examen"},model:{value:(_vm.exam_fees),callback:function ($$v) {_vm.exam_fees=$$v},expression:"exam_fees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Niveau","label-for":"Niveau"}},[_c('validation-provider',{attrs:{"name":"Niveau","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Niveau"},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-form-group',{attrs:{"label":"Active","label-cols":"10"}},[_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"name":"is-active","switch":"","inline":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addEmployee.apply(null, arguments)}}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }